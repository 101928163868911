import Payment from '../src/components/Payment'
import '../src/components/PaymentPage.css'

function App() {
  return (
    <div className="App">
     <Payment/>
    </div>
  );
}

export default App;