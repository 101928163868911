import React, { useState } from 'react';
import './PaymentPage.css';
import respondao from '../components/respondaoIcon.png';

const PaymentPage = () => {
    const [paymentMethod, setPaymentMethod] = useState('pix');
    const [isCopySuccess, setIsCopySuccess] = useState(false);

    const handlePaymentMethodChange = (event) => {
        setPaymentMethod(event.target.value);
    };

    const copyPixKeyToClipboard = () => {
        const pixKeyElement = document.getElementById('pix-key');

        if (pixKeyElement) {
            const textToCopy = pixKeyElement.innerText;
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    setIsCopySuccess(true); // Define a flag de sucesso como verdadeira
                    setTimeout(() => setIsCopySuccess(false), 3000); // Limpa a mensagem de sucesso após 3 segundos
                })
                .catch((error) => {
                    console.error('Erro ao copiar a chave PIX: ', error);
                });
        }
    };

    return (
        <div className="payment-container">
            <div className="product-details">
                <h2>Respondão</h2>
                <p className="price centered-text">Por apenas: R$6.99</p>
                <img src={respondao} alt="Descrição da Imagem" className="product-image" />
            </div>

            <div className="payment-method-selection">
                <label className="payment-method">
                    <input
                       type="radio" 
                       name="paymentMethod" 
                       value="pix" 
                       checked={paymentMethod === 'pix'} 
                       onChange={handlePaymentMethodChange} 
                   />
                   PIX
                </label>
            </div>

            <div className="payment-method-selection">
                <label className="payment-method">
                    <input
                        type="radio"
                        name="paymentMethod"
                        value="card"
                        checked={paymentMethod === 'card'}
                        onChange={handlePaymentMethodChange}
                    />
                    Cartão de Crédito
                </label>
            </div>

            {paymentMethod === 'card' ? (
                <div className="card-payment">
                    <p>Para pagar com cartão, clique no botão abaixo:</p>
                    <button
                        className="submit-button"
                        onClick={() => (window.location.href = 'https://pay.kiwify.com.br/AajT7h9')}
                    >
                        Pagar $6.99 com Cartão
                    </button>
                </div>
            ) : (
                <div className="pix-details">
                    <p>Para pagar com PIX, use a chave abaixo:</p>
                    <p id="pix-key" className="pix-key">00020126580014BR.GOV.BCB.PIX0136d2f7280d-06a9-424e-935e-7b8255464b2252040000530398654046.995802BR5925altair junior lourenco it6009SAO PAULO62140510XuSd4i7nPi63040BDF</p>
                    <button className="copy-pix-button" onClick={copyPixKeyToClipboard}>Copiar Chave PIX</button>
                </div>
            )}

            {isCopySuccess && (
                <div className="copy-success-message">
                    Código PIX copiado com sucesso!
                </div>
            )}

            <div className="payment-notice">
                <p>
                    Após o pagamento, mandar o comprovante para o número: Clica para ir pro whatsapp{' '}
                    <a
                        href="https://wa.me/+5524998824667"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="contact-number"
                    >
                        +5524998824667
                    </a>
                </p>
            </div>
        </div>
    );
};

export default PaymentPage;
